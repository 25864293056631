<template>
    <div class="content">
        <div class="page-info">
            <van-uploader v-model="fileList" :after-read="afterRead" :max-count="1"/>
            <van-cell-group>
                <van-field v-model="params.name" label="名称" placeholder="请输入" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.price" label="价格" placeholder="请输入" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.amount" label="数量" placeholder="请输入" />
            </van-cell-group>
            <van-cell-group>
                <van-field v-model="params.remark" label="备注" placeholder="请输入" />
            </van-cell-group>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" @click="submit">
                提交
            </van-button>
        </div>
    </div>
</template>
<script>
    export default {
        name: "print",
        data() {
            return {
                params: {
                    name: '',
                    price: '',
                    amount: '',
                    img_src: '',
                    remark: ''
                },
                fileList: []
            }
        },
        components: {

        },
        created() {

        },
        mounted() {

        },
        methods: {
            afterRead(file) {
                let params = {
                    file: file.content
                }
                this.$util.ajax('/jh/manage/order/ossPut', 'POST', params).then(res => {
                    if (res.code === 200){
                        this.params.img_src = res.data.name
                        this.fileList = [
                            {url: res.data.url}
                        ]
                    }
                })
            },
            submit() {
                let params = this.params
                // if (!params.img_src){
                //     this.$toast("请上传图片")
                //     return false
                // }
                // if (!params.name){
                //     this.$toast("请输入名称")
                //     return false
                // }
                // if (!params.price){
                //     this.$toast("请输入价格")
                //     return false
                // }
                // if (!params.amount){
                //     this.$toast("请输入数量")
                //     return false
                // }
                this.$util.ajax('/jh/manage/order/create', 'POST', params).then(res => {
                    if (res.code !== 200){
                        this.$Toast("创建失败")
                        return false
                    }
                    this.$Toast("创建成功")
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .content {
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        padding: 0.32rem 0.32rem;
        background: #f0f0f0;
        .page-info {
            padding-bottom: 0.32rem;
        }
        .list {
            .line {
                padding-bottom: 0.32rem;
            }
            .add {
                font-size: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333;
            }
        }
    }
</style>
